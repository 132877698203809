@import (reference) './imports/global';
@import (reference) './imports/aui-theme/components/dropdown';

@aui-select-image-size: 16px;
@aui-select-image-in-input-top: 6px;
@aui-select-image-in-input-left: 10px;
@aui-select-image-in-input-right: 5px;
@aui-select-image-vertical-padding: 2px;
@aui-select-image-horizontal-padding: 6px;

.aui-popover {
    --aui-item-border-radius: 0;

    & {
        #aui-dropdowns.aui-dropdown-style();
        box-sizing: border-box;
        max-width: 300px;
        min-width: 160px;
        padding: @aui-dropdown-group-spacing 0;
    }

    [role="option"] {
        color: inherit;
        display: block;
        padding: 3px 10px;
        text-decoration: none;

        &.aui-select-suggestion {
            cursor: pointer;
        }
    }

    img {
        display: inline-block;
        height: @aui-select-image-size;
        position: relative;
        padding-right: @aui-select-image-horizontal-padding;
        top: @aui-select-image-vertical-padding;
        vertical-align: baseline;
        width: @aui-select-image-size;
    }

    ul {
        list-style: none;
        margin: 0;
        padding-left: 0;

        [role="option"] {
            #aui-nav.item-base();
            #aui-nav.item-style(normal);

            &:not(.aui-select-no-suggestions):hover {
                #aui-nav.item-style(hover);
            }

            &:not(.aui-select-no-suggestions):active {
                #aui-nav.item-style(active);
            }
        }

        &:not(:hover) [role="option"].aui-select-active {
            #aui-nav.item-style(hover);
        }
    }

    &.aui-layer {
        height: auto;
    }
}
@select-trigger-padding-left: 6px;
@select-trigger-padding-right: 6px;
@select-trigger-arrow-width: 8px;
@select-trigger-width: @aui-button-border-width * 2 + @select-trigger-padding-left + @select-trigger-arrow-width + @select-trigger-padding-right;
@select-trigger-arrow-height: @select-trigger-arrow-width / 2;

form.aui:not(.aui-legacy-forms) aui-select,
aui-select {

    display: inline-block;
    position: relative;
    max-width: @aui-form-field-width-default;
    width: 100%;

    #aui-forms.aui-input-field-style(normal);
    font-size: @aui-font-size-medium;

    &:hover { // need this to make same hover for input on actually button hover
        input {
            #aui-forms.aui-input-field-style(hover);
        }
    }

    &:not([resolved]) {
        display: inline-block;
        height: @aui-form-field-height;
        width: 100%;
        max-width: 250px;
        vertical-align: bottom;
    }

    aui-option:not([resolved]) {
        display: none;
    }

    input.aui-select-has-inline-image {
        background-position: @aui-select-image-in-input-left @aui-select-image-in-input-top;
        background-repeat: no-repeat;
        background-size: @aui-select-image-size;
        padding-left: @aui-select-image-size + @aui-select-image-in-input-left + @aui-select-image-in-input-right;
    }

    select,
    datalist {
        display: none;
    }

    input.text {
        padding-right: @select-trigger-width;
        max-width: 100%;
        background-color: @aui-form-select-bg-color;
    }

    button.aui-button {
        background: transparent;
        outline: none;
        bottom: 0;
        box-sizing: border-box;
        display: inline-block;
        height: @aui-form-field-height;
        right: 0;
        margin: 0;
        position: absolute;
        top: 0;
        vertical-align: top;
        width: @select-trigger-width;

        &:not([aria-busy])::before {
            #aui.aui-dropdown2-trigger-chevron-icon();
        }

        &[aria-expanded="true"]:not([aria-busy])::before {
            transform: rotate(180deg);
        }
    }
}

form.aui.aui-legacy-forms aui-select {
    #aui-legacy-forms.colors();

    display: inline-block;
    position: relative;
    max-width: @aui-form-field-width-default;
    width: 100%;

    &:not([resolved]) {
        display: inline-block;
        height: @aui-form-field-height;
        width: 100%;
        max-width: 250px;
        vertical-align: bottom;
    }

    aui-option:not([resolved]) {
        display: none;
    }

    input.aui-select-has-inline-image {
        background-position: @aui-select-image-in-input-left @aui-select-image-in-input-top;
        background-repeat: no-repeat;
        background-size: @aui-select-image-size;
        padding-left: @aui-select-image-size + @aui-select-image-in-input-left + @aui-select-image-in-input-right;
    }

    select,
    datalist {
        display: none;
    }

    input.text {
        padding-right: @select-trigger-width;
        max-width: 100%;
    }

    button.aui-button {
        background-color: @aui-button-default-bg-color;
        background-clip: padding-box;
        border-color: @aui-legacy-form-field-border-color;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        bottom: 0;
        box-sizing: border-box;
        display: inline-block;
        height: @aui-form-field-height;
        right: 0;
        margin: 0;
        position: absolute;
        top: 0;
        vertical-align: top;
        width: @select-trigger-width;

        &:not(:hover) {
            border-top-color: transparent;
            border-right-color: transparent;
            border-bottom-color: transparent;
        }

        &:not([aria-busy])::before {
            #aui.aui-dropdown2-trigger-chevron-icon();

            right: ((@select-trigger-width - @aui-icon-size-small - (@aui-button-border-width *2)) / 2);
        }
    }
}

//Form notification + single select
form.aui {
    aui-select {
        &[data-aui-notification-error] .text {
            border-color: @aui-notification-error-color;
        }

        &[data-aui-notification-success] .text {
            border-color: @aui-notification-success-color;
        }
    }
}
