@import (reference) './imports/global';

@aui-label-close-button-width: 16px;

/*! AUI Label */
.aui-label {
    background: @aui-label-bg-color;
    border: 1px solid @aui-label-border-color;
    border-radius: @aui-label-border-radius;
    display: inline-block;
    font-size: @aui-font-size-medium;
    font-weight: normal;
    line-height: 1;
    padding: 1px 5px;
    margin: 0 5px 0 0;
    text-align: left;
    text-decoration: none;
}

a.aui-label {
  color: @aui-label-link-color;
}

span.aui-label {
    color: @aui-label-text-color;
}

/* Need the split hover to trigger border on parent while split label/X are hovered */
.aui-label.aui-label-closeable.aui-label-split:hover,
a.aui-label:focus,
a.aui-label:hover,
a.aui-label:active {
    border-color: @aui-label-hover-border-color;
    text-decoration: none;
}

.aui-label-split .aui-label-split-main:hover,
.aui-label-split .aui-label-split-main:active,
.aui-label-split .aui-label-split-main:focus{
    text-decoration: none;
}

.aui-label.aui-label-closeable {
    padding-right: @aui-label-close-button-width + 2px;
    position: relative;
}

.aui-label-closeable .aui-icon-close {
    background: none;
    border-radius: @aui-label-border-radius;
    cursor: pointer;
    display: flex; // allows us to position the glyph in the absolute center
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: @aui-label-close-button-width;
    filter: opacity(0.5);

    //The close cross
    &::before {
        #aui.icon-pseudoelement(@aui-icon-font-family);
        content: @aui-glyph-close;
        font-size: 10px;
        margin: auto;
    }

    &:hover, &:focus {
        background: var(--aui-label-close-hover-bg-color);
        color: var(--aui-label-close-hover-text-color);
        filter: none;
    }
}
