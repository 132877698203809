@import (reference) './imports/global';

/**
 * Quick search for header
 */
.aui-header .aui-quicksearch {
    //
    // Variables
    //
    @bg-color: var(--aui-appheader-quicksearch-bg-color);
    @border-color: var(--aui-appheader-quicksearch-border-color);
    @text-color: var(--aui-appheader-quicksearch-text-color);
    @placeholder-color: var(--aui-appheader-quicksearch-placeholder-text-color);
    @focus-bg-color: var(--aui-appheader-quicksearch-focus-bg-color);
    @focus-text-color: var(--aui-appheader-quicksearch-focus-text-color);
    @width: 170px;
    @border-radius: 3px;
    @box-shadow: none;
    @icon: @aui-glyph-search-small;

    @icon-height: @aui-icon-size-small;
    @icon-width: @aui-icon-size-small;

    @padding-left-right: @aui-grid;
    @icon-gutter: 30px;
    // this fudging number pushes the text slightly higher so it "lines up" with the text in menu items and buttons.
    // it's 1px too low in Chrome, and 1px too high in Firefox and IE11. A compromise, if you will.
    @text-baseline-fudge: 1px;

    //
    // Layout concerns:
    // The container needs to be relative so we can absolutely position the icon within it.
    // We use flexbox so that vertical alignment is handled by the browser rather than magic margin numbers.
    //
    display: flex;
    justify-content: flex-end; // push to the RHS of the container.
    align-items: center; // vertically center the elements.
    position: relative;
    margin: 0;

    // ...and some cosmestic stuff.
    color: @text-color;

    input {
        background: @bg-color;
        border: @aui-form-field-border-width solid @border-color;
        border-radius: @border-radius;
        box-shadow: none;
        box-sizing: border-box;
        color: @text-color;
        height: unit(30 / @aui-font-size-medium, em); // 30px effective - need height in ems so that user-specified font-sizes apply
        font-family: inherit;
        font-size: @aui-font-size-medium;
        padding: 0 @padding-left-right 0 @icon-gutter;
        vertical-align: baseline;
        width: @width;

        &::placeholder {
            color: @placeholder-color;
        }
    }

    // Override the browser appearance of type=text, type=search and type=button.
    input[type] {
        appearance: none;
    }

    input::-webkit-search-cancel-button {
        appearance: none;
    }

    // icon
    &::before {
        #aui.icon-pseudoelement(@aui-icon-font-family);

        // Layout concerns:
        // because of a flexbox parent + align-self, there's no need to
        // mess with margins or positioning relative to the top of the container; it's just done by flexbox :D
        align-self: center;
        pointer-events: none; // so the icon isn't clickable, so clicks on it will focus the input field
        position: absolute;
        left: (@icon-gutter - @icon-width) / 2; // sneakily shift the icon inside the input field

        // And now the actual icon concerns:
        content: @icon;
        color: @placeholder-color;
        font-size: @aui-icon-size-small;
        line-height: 1;
        height: @icon-height;
        width: @icon-width;
        z-index: 1;
    }

    &:focus-within::before {
        color: @text-color;
    }

    input {
        &:focus {
            #aui.with-focus-border();

            background: @focus-bg-color;
            color: @focus-text-color;
        }
    }

    &:focus-within::after {
        color: @focus-text-color;
    }
}
