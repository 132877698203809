@import (reference) '../theme';
@import (reference) '../../mixins';

@aui-button-border-color: transparent;

@aui-button-effective-height: 30px;

@aui-button-border-radius: @aui-border-radius-smallish;
@aui-button-border-style: solid;
@aui-button-border-width: 1px;
@aui-button-font-size: @aui-font-size-medium;
@aui-button-height: unit(@aui-button-effective-height / @aui-button-font-size, em);
@aui-button-padding-x: 10px;
@aui-button-padding-y: 4px;
@aui-button-line-height: unit((@aui-button-effective-height - (@aui-button-border-width + @aui-button-padding-y) *2) / @aui-button-font-size, em);

// Default button
@aui-button-default-bg-color: var(--aui-button-default-bg-color);
@aui-button-default-hover-bg-color: var(--aui-button-default-hover-bg-color);
@aui-button-default-text-color: var(--aui-button-default-text-color);

@aui-button-default-active-bg-color: var(--aui-button-default-active-bg-color);
@aui-button-default-active-text-color: var(--aui-button-default-active-text-color);

@aui-button-default-selected-bg-color: var(--aui-button-default-selected-bg-color);
@aui-button-default-selected-text-color: var(--aui-button-default-selected-text-color);

@aui-button-default-disabled-bg-color: var(--aui-button-default-disabled-bg-color);
@aui-button-default-disabled-text-color: var(--aui-button-default-disabled-text-color);

// Primary button
@aui-button-primary-bg-color: var(--aui-button-primary-bg-color);
@aui-button-primary-text-color: var(--aui-button-primary-text-color);

@aui-button-primary-hover-bg-color: var(--aui-button-primary-hover-bg-color);
@aui-button-primary-active-bg-color: var(--aui-button-primary-active-bg-color);
@aui-button-primary-active-text-color: var(--aui-button-primary-active-text-color);

@aui-button-primary-disabled-bg-color: var(--aui-button-primary-disabled-bg-color);
@aui-button-primary-disabled-text-color: var(--aui-button-primary-disabled-text-color);

@aui-button-transition: background-color 0.1s ease-out;

@aui-grouped-button-spacing: 2px;

#aui-buttons {
    .aui-button-base() {
        #aui.transition(@aui-button-transition);
        border-radius: @aui-button-border-radius;
        border-style: @aui-button-border-style;
        border-width: @aui-button-border-width;
        cursor: pointer;
        font-family: inherit;
        font-size: @aui-button-font-size;
        font-variant: normal;
        font-weight: @aui-font-weight-normal;
        .aui-button-style();
    }

    .aui-button-style() {
        background-image: none;
        background-color: var(--aui-btn-bg);
        border: @aui-button-border-width @aui-button-border-style var(--aui-btn-border);
        color: var(--aui-btn-text);
    }

    .aui-button-style(normal) {
        --aui-btn-bg: var(--aui-button-default-bg-color);
        --aui-btn-border: var(--aui-button-default-border-color, transparent);
        --aui-btn-text: var(--aui-button-default-text-color);
        text-decoration: none;
    }

    .aui-button-style(hover) {
        --aui-btn-bg: var(--aui-button-default-hover-bg-color);
        --aui-btn-text: var(--aui-button-default-text-color);
    }

    .aui-button-style(active) {
        --aui-btn-bg: var(--aui-button-default-active-bg-color);
        --aui-btn-text: var(--aui-button-default-active-text-color);
    }

    .aui-button-style(selected) {
        --aui-btn-bg: var(--aui-button-default-selected-bg-color);
        --aui-btn-text: var(--aui-button-default-selected-text-color);
    }

    .aui-button-style(disabled) {
        --aui-btn-bg: var(--aui-button-default-disabled-bg-color);
        --aui-btn-border: var(--aui-button-default-disabled-border-color, transparent);
        --aui-btn-text: var(--aui-button-default-disabled-text-color);
        cursor: default;
    }

    .aui-primary-button-style(normal) {
        --aui-btn-bg: var(--aui-button-primary-bg-color);
        --aui-btn-text: var(--aui-button-primary-text-color);
        font-weight: @aui-font-weight-semibold;
    }

    .aui-primary-button-style(hover) {
        --aui-btn-bg: var(--aui-button-primary-hover-bg-color);
        --aui-btn-text: var(--aui-button-primary-active-text-color);
    }

    .aui-primary-button-style(active) {
        --aui-btn-bg: var(--aui-button-primary-active-bg-color);
        --aui-btn-text: var(--aui-button-primary-active-text-color);
    }

    .aui-primary-button-style(disabled) {
        #aui-buttons.aui-button-style(disabled);
    }

    .aui-warning-button-style(normal) {
        --aui-btn-bg: var(--aui-button-warning-bg-color);
        --aui-btn-text: var(--aui-button-warning-text-color);
        font-weight: @aui-font-weight-medium;
    }

    .aui-warning-button-style(hover) {
        --aui-btn-bg: var(--aui-button-warning-hover-bg-color);
        --aui-btn-text: var(--aui-button-warning-active-text-color);
    }

    .aui-warning-button-style(active) {
        --aui-btn-bg: var(--aui-button-warning-active-bg-color);
        --aui-btn-text: var(--aui-button-warning-active-text-color);
    }

    .aui-warning-button-style(disabled) {
        #aui-buttons.aui-button-style(disabled);
    }

    .aui-danger-button-style(normal) {
        --aui-btn-bg: var(--aui-button-danger-bg-color);
        --aui-btn-text: var(--aui-button-danger-text-color);
        font-weight: @aui-font-weight-medium;
    }

    .aui-danger-button-style(hover) {
        --aui-btn-bg: var(--aui-button-danger-hover-bg-color);
        --aui-btn-text: var(--aui-button-danger-active-text-color);
    }

    .aui-danger-button-style(active) {
        --aui-btn-bg: var(--aui-button-danger-active-bg-color);
        --aui-btn-text: var(--aui-button-danger-active-text-color);
    }

    .aui-danger-button-style(disabled) {
        #aui-buttons.aui-button-style(disabled);
    }

    .aui-subtle-button-style(normal) {
        --aui-btn-bg: var(--aui-button-subtle-bg-color, transparent);
        --aui-btn-border: var(--aui-button-subtle-border-color, transparent);
        --aui-btn-text: var(--aui-button-subtle-text-color, currentcolor);
    }

    .aui-link-button-style(normal) {
        --aui-btn-bg: transparent;
        --aui-btn-border: transparent;
        --aui-btn-text: var(--aui-link-color);
        cursor: pointer;
        text-decoration: var(--aui-link-decoration, none);
    }

    .aui-link-button-style(hover) {
        --aui-btn-bg: transparent;
        --aui-btn-text: var(--aui-link-hover-color);
        text-decoration: var(--aui-link-hover-decoration, underline);
    }

    .aui-link-button-style(active) {
        --aui-btn-bg: transparent;
        --aui-btn-text: var(--aui-link-active-color);
        text-decoration: var(--aui-link-active-decoration, none);
    }

    .aui-link-button-style(disabled) {
        --aui-btn-bg: transparent;
        --aui-btn-border: transparent;
        --aui-btn-text: inherit;
        filter: opacity(0.44);
        text-decoration: none;
    }
}
