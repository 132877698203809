@import (reference) './imports/global';
@import (reference) './imports/mixins/focus';

/**
 * Dropdown 2
 */

// Captures the various dropdown item selectors for ease of repetition
.dropdown-items(@rules) {
    .aui-dropdown2-checkbox,
    .aui-dropdown2-radio,
    a,
    button {
        @rules();
    }
}

// Abstracts which pseudo-element we use for the dropdown chevron glyph
.dropdown-icon(@rules) {
    &::before {
        @rules();
    }
}

/* Functional styles - these are always applied
---------------------------------------------------------------------- */
.aui-dropdown2 {
    box-sizing: border-box;
    max-width: 300px;
    min-width: 160px;

    &:not([resolved]) {
        display: none;
    }
}

.aui-dropdown2 {
    --aui-item-border-radius: 0;

    // undo some browser defaults
    button {
        -webkit-appearance: none;
        border-width: 0;
        font: inherit;
        margin: 0;
        text-align: left;
        // these could be removed if the parent was `display: flex`... but that might mess up consumers' CSS overrides
        width: -moz-available;
        width: -webkit-fill-available;
        width: fill-available;
    }

    .dropdown-items({
        #aui.prevent-text-selection();
        #aui-nav.item-base();

        & :focus {
            z-index: 1;
        }
    });

    .aui-list-truncate {
        .dropdown-items({
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        });
    }
}

/* Aesthetic style for Dropdown2
---------------------------------------------------------------------- */
.aui-dropdown2 {
    #aui-dropdowns.aui-dropdown-style();
    padding: @aui-dropdown-group-spacing 0;

    ul {
        list-style: none;
        margin: 0;
        padding-left: 0;
    }

    .aui-dropdown2-section {
        margin-top: @aui-dropdown-group-spacing;

        &:first-child {
            margin-top: 0;
        }

        + .aui-dropdown2-section {
            border-top: 1px solid @aui-dropdown2-border-color;
            padding-top: @aui-dropdown-group-spacing;
        }
    }

    > strong,
    .aui-dropdown2-section > strong,
    .aui-dropdown2-heading {
        #aui.typography.h100(@aui-dropdown-heading-text-color);
        display: block;
        padding: @aui-nav-link-spacing-vertical @aui-nav-link-spacing-horizontal;

        &:empty {
            display: none;
        }
    }

    .aui-dropdown2-heading > strong {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
    }

    strong + ul {
        margin-top: 0;
    }

    //
    // Dropdown items
    //
    .dropdown-items({
        #aui-nav.item-style(normal);
        #aui.focus(#aui.with-focus-ring());
        outline-offset: 0;

        &:not(.aui-dropdown2-disabled):hover {
            #aui-nav.item-style(hover);
        }

        &:not(.aui-dropdown2-disabled):active {
            #aui-nav.item-style(active);
        }
    });

    // If not hovering on the dropdown, the "hover" style is best for whatever has browser focus.
    .aui-dropdown2-active {
        #aui-nav.item-style(hover);
    }

    // Set disabled styles last, so that the cascade gives them a higher precedence.
    &:hover .aui-dropdown2-disabled,
    .aui-dropdown2-disabled,
    [aria-disabled="true"],
    a.disabled {
        #aui-nav.item-style(disabled);
    }

    //
    // Radios and checkboxes
    //
    .aui-icon-container,
    .aui-dropdown2-radio,
    .aui-dropdown2-checkbox {
        padding-left: 35px;
        background-position: @aui-nav-link-spacing-horizontal @aui-nav-link-spacing-vertical;
        background-repeat: no-repeat;
        position: relative;
    }

    .aui-icon-container > img,
    .aui-icon-container > .aui-icon,
    .aui-icon-container > .aui-avatar {
        border-width: 0;
        display: inline-block;
        left: @aui-nav-link-spacing-horizontal;
        overflow: hidden;
        position: absolute;
        top: @aui-nav-link-spacing-vertical;
    }

    // Checkbox items
    .aui-dropdown2-checkbox.aui-dropdown2-checked {
        #aui.icon(@aui-glyph-check, {
            margin-left: (@aui-icon-size-small / 2);
        });
    }

    // Radio items
    .aui-dropdown2-radio.aui-dropdown2-checked {
        #aui.icon(@aui-glyph-radio, {
            margin-left: (@aui-icon-size-small / 2);
        });
    }
}

/* Tailed dropdown variant */
.aui-dropdown2.aui-dropdown2-tailed {
    &::before,
    &::after {
        border-color: transparent;
        border-style: outset outset solid outset;
        border-width: @aui-dropdown2-tail-size;
        bottom: 100%;
        content: "";
        display: block;
        height: 0;
        position: absolute;
        width: 0;
    }

    &::before {
        border-bottom-color: @aui-dropdown2-border-color;
        margin-bottom: 1px;
    }

    &::after {
        border-bottom-color: @aui-dropdown2-bg-color;
    }
}

/* Arrows for menu triggers */
.aui-dropdown2-trigger {
    --aui-dropdown-ig: @aui-glyph-chevron-down;
}

.aui-dropdown2-trigger:not(.aui-dropdown2-trigger-arrowless) {
    // NOTE: the !important overrides any context-specific paddings (e.g., app header items).
    // This could be removed if all dropdown triggers were display:flex|inline-flex...
    // ...but that's hard to guarantee.
    padding-right: var(--aui-dropdown-icon-gutter, @aui-icon-size-small-canvas) !important;
    position: relative;

    .dropdown-icon({
        #aui.aui-dropdown2-trigger-chevron-icon();
        content: var(--aui-dropdown-ig);
    });
}

.aui-dropdown2-trigger[aria-expanded="true"]:not(.aui-dropdown2-trigger-arrowless) {
    .dropdown-icon({
        transform: rotate(180deg);
    });

    &.aui-dropdown2-sub-trigger {
        .dropdown-icon({
            transform: rotate(90deg);
        });
    }
}

.aui-dropdown2-sub-trigger {
    --aui-dropdown-ig: @aui-glyph-chevron-right;
}

/* Dropdown2 button integration
---------- */

.aui-button-compact.aui-dropdown2-trigger {
    --aui-dropdown-icon-gutter: 21px;
}

/* INPUT does not play with ::after - dropdown2 does not support arrow styles for INPUT buttons */
/* We cannot position arrows on aui-button-text because we don't know what size they will be all the time */
input.aui-dropdown2-trigger,
.aui-button-text.aui-dropdown2-trigger {
    --aui-dropdown-icon-gutter: unset;
}

input.aui-button.aui-dropdown2-trigger,
.aui-button.aui-button-text.aui-dropdown2-trigger {
    .dropdown-icon({
        display: none;
    });
}

/* Dropdown2-only / Split Button
---------- */
.aui-button-split-more {
    --aui-dropdown-ig: @aui-glyph-more-actions;
}

.aui-buttons .aui-button.aui-button-split-more.aui-dropdown2-trigger {
    /* don't touch vertical padding or things jump around, jump around, jump up jump up and get down */
    padding-left: 0;
    padding-right: 0;
    width: @aui-icon-size-small-canvas;
    text-indent: -9999em;
    overflow: hidden;
}

/* Dropdown2 layer integration */
.aui-dropdown2.aui-layer {
    // To override the specific height calculations for layer.
    // The height will be handled by JS.
    & {
        -webkit-height: auto;
        height: auto;
    }

    &[data-popper-placement*="bottom"] {
        &.aui-dropdown2-tailed {
            margin-top: @aui-dropdown2-tail-size;
        }
    }

    &.aui-alignment-side-submenu {
        // so that the first item in a submenu dropdown aligns horizontally
        // with the submenu's trigger.
        margin-top: -(@aui-dropdown-group-spacing);
    }
}

/**
 * Dropdown2 web components
 */
aui-dropdown-menu,
aui-dropdown-group,
aui-section,
aui-item-checkbox,
aui-item-radio,
aui-item-link {
    display: block;
}

// Async dropdown
aui-dropdown-menu {
    &:not([resolved]) {
        display: none;
    }

    .aui-dropdown-loading {
        padding: 5px;

        aui-spinner {
            display: inline-block;
            vertical-align: bottom;
        }
    }
}
