@import (reference) './imports/global';
@import (reference) './imports/mixins/focus';

/**
 * Toggle button
 */

aui-toggle {
    border-radius: @aui-toggle-border-radius; // So that focus styles won't just look like a box
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    font-size: @aui-toggle-font-size;
    height: @aui-toggle-height;
    padding: @aui-toggle-vertical-padding @aui-toggle-horizontal-padding;
    position: relative;
    vertical-align: baseline;
    width: @aui-toggle-width;

    .aui-toggle-view .aui-icon {
        --aui-icon-size: @aui-toggle-icon-size;
    }

    // because all content inside this control is absolute position, we need some text so that
    // the vertical alignment works
    &::before {
        content: "\00a0"; // non-breaking space char
    }

    // invisible "overlay" which intercepts mouse clicks and provides the tint for the disabled/busy states
    &::after {
        background-color: transparent;
        border-radius: @aui-toggle-border-radius;
        bottom: 0;
        content: '';
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
    }

    // hover and focus state
    &:not([disabled]):not([busy]) {
        // Off
        &:hover .aui-toggle-input + .aui-toggle-view {
            background-color: @aui-toggle-default-bg-hover-color;
        }

        // On
        &:hover .aui-toggle-input:checked + .aui-toggle-view {
            background-color: @aui-toggle-on-hover-color;
        }
    }

    // disabled/busy state
    &[disabled],
    &[busy] {
        cursor: auto;

        .aui-toggle-input {
            cursor: auto;
            pointer-events: none;
        }
    }

    // busy state
    &[busy] {
        .aui-toggle-tick::before,
        .aui-toggle-cross::before {
            opacity: 0;
            transition: auto;
        }

        // tint the "overlay" to fade it out like a disabled control
        &::after {
            background-color: @aui-toggle-disabled-overlay-color;
        }

        // integration with spinner element
        .aui-icon aui-spinner[size="small"] {
            color: inherit;
            position: absolute;
            top: (@aui-toggle-icon-size - @aui-spinner-size-small) / 2; // because it is inside the icon.
            left: (@aui-toggle-icon-size - @aui-spinner-size-small) / 2; // because it is inside the icon.
        }
    }

    &[disabled] {
        filter: opacity(0.5);
    }
}

aui-toggle {
    // Render a focus ring when the internals have active browser focus:
    // the standards-compliant solution.
    &:focus-within {
        #aui.with-focus-ring();
    }

    // focus rendering for "other" browsers (*cough* IE *cough*)
    &.active {
        #aui.with-focus-ring();
    }
}

// the actual native checkbox to determine on/off/disabled state
.aui-toggle {
    &-input {
        -webkit-appearance: button;
        -moz-appearance: radio-container; // CSS appearance: none is broken on Firefox. We use this to hide the input. Works as if setting appearance: none;
        border-radius: @aui-toggle-border-radius;
        bottom: 0;
        cursor: pointer;
        height: 100%;
        left: 0;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }

    // this is what provides the visual styles for the component
    &-view {
        background-color: @aui-toggle-default-bg-color;
        border-radius: @aui-toggle-border-radius;
        bottom: 0;
        box-sizing: border-box;
        display: block;
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        transition: background-color @aui-toggle-animation-background-color-delay linear;
        top: 0;
    }

    // the "handle" which moves from side-to-side
    &-view::after {
        background-color: @aui-toggle-button-color;
        border-radius: 50%;
        bottom: @aui-toggle-border-width;
        content: '';
        display: block;
        left: @aui-toggle-border-width;
        position: absolute;
        top: @aui-toggle-border-width;
        transition: transform @aui-toggle-animation-slide-delay ease-in-out;
        width: @aui-toggle-handle-width;
    }

    // tick and cross icon
    &-tick,
    &-cross {
        position: absolute;
        top: @aui-toggle-icon-top;

        &::before {
            transition: opacity @aui-toggle-animation-visible-delay ease-in-out;
        }
    }

    &-tick {
        color: @aui-toggle-tick-color;
        left: @aui-toggle-horizontal-padding;
    }

    &-cross {
        color: @aui-toggle-cross-color;
        right: @aui-toggle-horizontal-padding;
    }

    // checked state (on/off)
    &-input:checked:enabled + &-view,
    &-input.indeterminate-checked + &-view {
        // .indeterminate-checked is to workaround Chrome and Safari no longer
        // matching :checked when .indeterminate is set to true.
        background-color: @aui-toggle-on-color;
    }

    // checked state (on/off)
    &-input:checked + &-view,
    &-input.indeterminate-checked + &-view {
        // .indeterminate-checked is to workaround Chrome and Safari no longer
        // matching :checked when .indeterminate is set to true.

        &::after {
            transform: translateX(@aui-toggle-slide-width);
        }
    }
}
