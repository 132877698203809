@import (reference) './imports/global';
@import (reference) './imports/mixins/focus';
@import (reference) './imports/aui-theme/components/forms';

form.aui.aui-legacy-forms {
    // Make the legacy colourings available within this scope.
    #aui-legacy-forms.colors();

    .text,
    .password,
    .upfile,
    .textarea,
    .select,
    .multi-select,
    .aui-select2-container {
        #aui-legacy-forms.aui-input-field-style(normal);
        font-size: @aui-font-size-medium;
        font-family: inherit;
    }

    .text,
    .password,
    .textarea,
    .select,
    .multi-select,
    .aui-select2-container .select2-choices {
        border: 1px solid @aui-legacy-form-field-border-color;
        border-radius: @aui-form-field-border-radius;
        box-sizing: border-box;
        font-size: inherit;
        margin: 0;
        vertical-align: baseline;
    }

    .text,
    .password,
    .select,
    .aui-select2-container .select2-choices {
        height: @aui-form-field-height;
        line-height: 1.4285714285714;
        padding: 4px 5px;
    }

    .textarea,
    .select[size],
    .multi-select {
        height: auto;
        line-height: 1.4285714285714;
        margin: 0;
        padding: 4px 5px;
    }

    optgroup {
        background-color: @aui-form-optgroup-bg-color;
        color: @aui-form-optgroup-text-color;
        font-style: normal;
        font-weight: normal;
    }

    option,
    optgroup option {
        background-color: @aui-form-option-bg-color;
        color: @aui-text-color;
    }

    .group .field-group,
    .date-select .field-group {
        clear: none;
        padding-left: 0;
        padding-top: 0;
    }
    .select {
        padding: 6px 5px 5px 5px; /* Firefox doesn't allow line-height to be adjusted and selects break horribly when the font-family is changed. Using padding instead */
        vertical-align: top;
    }

    /* Ensure AUI Select2's do not show as regular text fields */
    .aui-select2-container {
        border: 0;
        height: auto;
        padding: 0;
        vertical-align: baseline;
        width: 100%;
    }

    .aui-select2-container .select2-choices {
        height: auto;
        max-width: none;
    }

    .aui-select2-container.select2-container-active .select2-choices {
        #aui.with-focus-border();
    }

    .aui-select2-container.select2-container-multi .select2-choices {
        min-height: 0;
        background: @aui-legacy-form-field-default-bg-color;
    }

    /* Placeholder form element styles */

    .text, .password, .textarea {
        #aui.placeholder(@aui-legacy-form-placeholder-text-color);
    }

    .text[disabled],
    .password[disabled],
    .textarea[disabled],
    .select[disabled],
    .multi-select[disabled],
    .select[disabled] option,
    .select[disabled] optgroup,
    .multi-select[disabled] option,
    .multi-select[disabled] optgroup {
        background-color: @aui-legacy-form-disabled-field-bg-color;
        color: @aui-legacy-form-disabled-field-text-color;
    }

    .field-group,
    .group,
    .date-select {
        box-sizing: border-box;
        clear: both;
        padding: 4px 0 4px 145px;
        position: relative;
        margin: 1px 0;
        width: 100%;
    }

    legend + .field-group,
    legend + .checkbox,
    legend + .radio {
        margin-top: 0;
        padding-top: 5px;
    }

    .checkbox,
    .radio {
        padding: 0 0 0 20px;
        margin: 5px 0 0 0;
        position: relative;

        &:first-child {
            margin-top: 0;
        }
    }

    .group {
        padding-top: 0;
    }

    .icon-required {
        left: 100%;
        position: absolute;
        top: 5px;

        &::before {
            content: "*";
            position: absolute;
            left: 0;
            top: 0;
            text-indent: initial;
            color: @aui-form-error-text-color;
            line-height: 1;
            font-size: @aui-font-size-small;
        }
    }

    legend,
    label {
        color: @aui-legacy-form-label-text-color;
    }

    div.description {
        font-size: @aui-font-size-small;
        line-height: unit(20 / @aui-font-size-small);
        color: @aui-form-description-text-color;
        margin: 5px 0 0 0;

        &:first-child {
            margin-top: 0;
        }
    }

    legend,
    .field-group > label,
    .field-group > aui-label {
        float: left;
        margin-left: -145px;
        padding: 5px 0 0 0;
        position: relative;
        text-align: right;
        width: 130px;
        word-wrap: break-word;
    }

    .radio input.radio,
    .checkbox input.checkbox {
        @size: @aui-font-size-medium;
        box-sizing: border-box;
        font-size: @size;
        height: unit(20 / @size, em);
        left: 0;
        margin: 0;
        padding: 2px;
        position: absolute;
        vertical-align: baseline;
    }
}
